import { FunctionComponent } from 'react';

import { ButtonElements, ButtonIconPositions, ButtonIconTypes, ButtonVariants } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import { Layouts } from 'components/modules/public/layout/sections/Description/types';
import LearnMore from 'components/modules/public/layout/sections/LearnMore';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionLearnMoreVariantDefault: FunctionComponent<Props> = ({ translations }) => {
    const { headlineInternal, list } = translations;

    return (
        <StyledComponent className="public-section-learn-more-variant-default">
            <LayoutContainer>
                {headlineInternal && (
                    <div className="section-title">
                        <div
                            className="headline"
                            dangerouslySetInnerHTML={{ __html: headlineInternal }}
                        />
                    </div>
                )}
                <div className="learn-more-list">
                    {Array.isArray(list) && list.map((listElem, index) => (
                        <LearnMore
                            key={`${listElem.key}-${index}`}
                            {...listElem}
                            descriptionProps={{
                                ...listElem,
                                layout: Layouts.Left,
                                buttonProps: {
                                    href: listElem.buttonHref,
                                    children: listElem.buttonLabel,
                                    variant: ButtonVariants.Outline,
                                    className: 'arrow',
                                    element: ButtonElements.Anchor,
                                    icon: {
                                        value: '/images/home/arrow.svg',
                                        position: ButtonIconPositions.After,
                                        type: ButtonIconTypes.Image,
                                    },
                                },
                            }}
                        />
                    ))}
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicSectionLearnMoreVariantDefault;
