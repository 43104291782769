import { FunctionComponent } from 'react';

import { imageResizer } from 'utils/image';

import UnorderedList from 'components/layout/UnorderedList';
import Description from 'components/modules/public/layout/sections/Description';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutLearnMore: FunctionComponent<Props> = ({
    image,
    video,
    descriptionProps,
    unorderedListProps,
    isUnorderedList,
    unorderedListConfig,
}) => {
    return (
        <StyledComponent className="public-layout-learn-more">
            <div className="box-content">
                {!isUnorderedList && (
                    <Description {...descriptionProps} />
                )}
                {isUnorderedList && (
                    <UnorderedList
                        lists={unorderedListProps.description}
                        title={unorderedListProps.title}
                        {...unorderedListConfig}
                    />
                )}
            </div>
            {image && (
                <img
                    className="box-image"
                    src={imageResizer(image, { width: 950 })}
                    srcSet={imageResizer(image, { width: 950 }, true)}
                    loading="lazy"
                    alt={descriptionProps?.headline}
                />
            )}
            {video && (
                <iframe
                    src={video}
                    width="838"
                    height="838"
                    frameBorder="0"
                    allowFullScreen={true}
                />
            )}
        </StyledComponent>
    );
};

export default PublicLayoutLearnMore;
