import { FunctionComponent } from 'react';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutUnorderedList: FunctionComponent<Props> = ({ lists, customClass, title, disableMarkers }) => {
    return (
        <StyledComponent className="layout-unordered-list">
            <h3 className="unordered-list-title">{title}</h3>
            <ul className={classnames('unordered-list', customClass)}>
                {lists && lists.map((list, index) => {
                    return (
                        <li
                            key={index}
                            className={classnames({
                                'unordered-list-item': true,
                                'disabled-marker': Boolean(disableMarkers),
                            })}
                            dangerouslySetInnerHTML={{ __html: list }}
                        />
                    );
                })}
            </ul>
        </StyledComponent>
    );
};

export default LayoutUnorderedList;
