import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-content {
        padding: 2em 0;
        width: 45%;

        .public-layout-description {
            .copy {
                .description-wrapper {
                    .layout-html-styled-content {
                        font-size: 1em;
                    }
                }
            }
        }
    }

    .box-image {
        width: 50%;
    }

    @media all and (max-width: ${variables.desktopXS}) {

        .box-content {
            width: 100%;
        }

        .box-image {
            width: 60%;
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        flex-direction: column;

        .box-content {
            padding: 0;

            .public-layout-description {
                .copy {
                    text-align: center;
                    margin-right: 0;
                }

                .link {
                    justify-content: center;
                }
            }
        }

        .box-image {
            padding-top: 2em;
            width: 100%;
        }
    }
`;
