import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    .unordered-list-title {
        font-size: 2em;
        text-align: center;
        margin: 0 auto 1em;
    }

    .unordered-list {
        display: flex;
        flex-direction: column;
        gap: 1.4em;

        .unordered-list-item {
            position: relative;
            font-size: 14px;

            &:not(.disabled-marker) {
                &::before {
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    content: '';
                    background-color: #BA9449;
                    font-weight: bold;
                    display: inline-block;
                    width: 0.5em;
                    border-radius: 0.5em;
                    height: 0.5em;
                    margin-left: -1.6em;
                }
            }

            b {
                font-weight: 700;
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .unordered-list {

            .unordered-list-title {
                text-align: center;
            }
        }
    }
`;
